import React, { useState, useEffect } from "react";
import { G_API, G_DEF_COV } from '../constants/globalVar';
import axios from 'axios';

const PageSlider = (props) => {
  const [item, setItem] = useState(null);

  useEffect(() => {
    const inputType = props.inputType;

    var config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: G_API + 'sliders/page/' + inputType,
    };

    axios(config)
      .then((response) => {
        let item = response.data.DATAS.slider;
        if (item == null) {
          item = {
            "slider_img": null,
            "status": true,
            "title": props.inputType,
            "sub_title": "We make free online courses, information sharing events, and volunteer work for community development.",
            "action_label": "View More",
            "action_url": "/about",
            "type": "course",
            "id": 4,
            "__v": 0
          };
        }
        setItem(item);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [props.inputType]);

  const sliderStyle = {
    height: props.inputType === "our team" ? "200px" : "auto",
  };
  const sliderImageStyle = {
     objectFit: 'cover',
     height: props.inputType === "our team" ? "200px" : "auto",
     maxHeight: '500px'
    };

  const hideClass = props.inputType === "our team" ? "hide" : "";

  return (
    <div>
      {item && (
        <div className="carousel-item active" style={sliderStyle}>
          {item.slider_img != null && item.slider_img.src ? (
            <img
              className="w-100 small-slider"
              src={item.slider_img.src}
              alt={item.title}
              style={sliderImageStyle}
            />
          ) : (
            <img
              className="w-100 small-slider"
              src={"/assets/images/" + G_DEF_COV}
              alt="Cover"
              style={sliderImageStyle}
            />
          )}

          <div className={`carousel-caption `} style={sliderStyle}>
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className={`col-lg-7 col-md-10 col-sm-12 pt-0 ${hideClass}`}>
                  <h3
                    className="display-4 text-white mb-1 mt-2 pt-5 animated slideInDown"
                    style={{ fontSize: '2.3rem', textTransform: "capitalize" }}
                  >
                    {item.title}
                  </h3>
                  <p className="fs-5 text-white-50 mb-5 animated slideInDown">{item.sub_title}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* CSS for responsive behavior */}
      <style jsx="true">
        {`
        @media (max-width: 768px) {
          .carousel-item {
            height: ${props.inputType === "our team" ? "150px !important" : "auto !important"};
          }
          h3 {
            font-size: 1.5rem;
          }
          p {
            font-size: 1rem;
          }
        }
      `}
      </style>
    </div>
  );
}

export default PageSlider;
